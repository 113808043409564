@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

.absenceFeatureBlock {
  // background: url(../../../images/absence-wave-bg.png) no-repeat top center;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  display: flex;
  justify-content: center;
  background-size: 100%;

  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    .col,
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-auto {
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      width: 100%;
    }

    .col-lg-6 {
      @media (min-width: 992px) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (max-width: 750px) {
      max-width: 750px;
    }
  }

  .alignLeftText {
    text-align: left;
    font-family: "Open Sans";
  }

  .firstColAbsenceFeatures {
    margin-top: 20px;

    @media (max-width: 991px) {
      margin-top: 0px;
    }
  }
}

.absencesBackground {
  @media (max-width: 991px) {
    width: auto !important;
  }

  .absenceFeatureImage {
    width: 1200px;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
}
