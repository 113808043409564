.getStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1a90ff;

  .customH2 {
    color: white;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 26px;
  }

  .actionWrapper {
    padding: 20px;
    border-radius: 6px;
    background: #177bd8;

    @media (max-width: 490px) {
      border-radius: 0px;
    }
  }

  .secondaryText {
    opacity: 0.8;
    font-size: 16px;
    color: white;
    margin-top: 0px;
    @media (max-width: 400px) {
      text-align: center;
    }
  }

  .inputCustomized {
    background: white;
    max-width: 260px;
    @media (max-width: 490px) {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
  }

  .buttonCustomizedGetStarted {
    background-color: #12adf3;
    color: #ffffff;

    @media (max-width: 490px) {
      width: 100%;
      margin-top: 10px;
    }
    &:hover {
      background-color: #12adf3;
      opacity: 0.8 !important;
    }
  }
}
