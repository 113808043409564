@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";

.mainBetaPage {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainFunctionsContainer {
  height: 800px;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    height: auto;
    padding-top: 160px !important;
  }
  @media (max-width: 400px) {
    height: auto;
    padding-top: 100px !important;
  }
  .heroImageWrapper {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .shiftplanPageH1 {
    font-size: 56px;
    padding-bottom: 10px;
    font-weight: 700;
    color: white;
    @media (max-width: 991px) {
      color: #5b636c;
    }
  }
}

// font-size: 22px;
//     margin-bottom: 28px;

.shiftplanTextCols {
  display: flex;
  background: whitesmoke;
  flex-wrap: wrap;
  padding: 120px;
  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 660px) {
    padding: 20px;
  }
  .textCol {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 40px;
    width: 33%;
    @media (max-width: 1200px) {
      text-align: center;
    }
    @media (max-width: 990px) {
      width: 100%;
    }
    .iconWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .discriptionHead {
      color: #283845;
      font-weight: 700;
      font-family: "Poppins";
      margin-top: 0 !important;
    }

    .discriptionLink {
      padding: 20px;
      width: 100%;
    }
  }
}

.shiftplanLandingImg {
  border-radius: 3px;
  box-shadow: 0px 1px 6px 1px #e2e2e2;
  border: 1px solid #e2e2e2;
}

.secondaryShiftplanText {
  font-size: 22px;
  margin-bottom: 28px;
  color: rgb(213, 213, 213);
  line-height: 29px;
  @media (max-width: 991px) {
    color: #5b636c;
  }
}
.inputShiftplanPage {
  background: whitesmoke;
  min-width: 260px;
  @media (max-width: 991px) {
    background: white;
  }
  @media (max-width: 490px) {
    text-align: center;
    margin-right: 0px !important;
    width: 100%;
    max-width: 100% !important;
  }
}
