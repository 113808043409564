.referencesLinkMain {
  display: flex;
  padding-top: 30px;
  // margin-bottom: 60px;
  justify-content: center;
  background-color: #f9f9f9;
  .referencesLinkWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .referencesLinkTitle {
      font-weight: bold;
      padding: 20px 0;
      // color: #3d9adc;
      padding-bottom: 20px;
      text-align: left;
      line-height: 44px;
      font-size: 30px;
      color: #19a0ff;
      // @media (max-width: 767px) {
      text-align: center;
      // }
      @media (max-width: 476px) {
        font-size: 38px;
      }
    }

    .referencesLinkDescription {
      text-align: center;
      // font-size: 22px;
      max-width: 800px;
      // line-height: 32px;

      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.55;
      color: #01164d;
    }

    .referencesLinkLogos {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;

      .testimonialLogoWrapper {
        display: flex;
        flex-direction: column;
        min-height: 90px;
        justify-content: space-between;
        margin: 16px;

        @media (max-width: 476px) {
          min-height: 70px;
        }
        .referencesLinkLogoImg {
          width: 180px;

          @media (max-width: 476px) {
            width: 120px;
          }
        }

        .testimonialReviewStars {
          color: #ffca1b;
          display: flex;
          justify-content: center;
          @media (max-width: 767px) {
            width: 120px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
