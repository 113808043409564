.customerSuccessMain {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;

  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 490px) {
    padding-bottom: 0px;
  }

  .successHeadingTextWrap {
    width: 33.33%;
    width: calc(100% / 3);
    padding: 20px;
    background: #19a0ff;
    margin-left: 0px;
    color: white;
    margin-right: 0px;
    @media (max-width: 1200px) {
      margin: 10px;
      width: 45%;
    }
    @media (max-width: 660px) {
      width: 100%;
    }
    .customerSuccessHeader {
      padding-bottom: 20px;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;

      color: white;
      text-align: left;
      @media (max-width: 800px) {
        font-size: 26px;
      }
    }
    .customerSuccessDescription {
      font-size: 18px;
      font-weight: 400;
      // color: #1a90ff;
      // color: grey;
      text-align: left;
      width: 100%;
      // width: 600px;
      // margin: 0 auto;
      @media (max-width: 800px) {
        // width: 400px;
      }
    }
  }
  .customerSuccessWrapper {
    width: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    // @media (max-width: 800px) {
    //   position: relative;
    //   padding-bottom: 55.25%;
    //   width: 100%;
    // }

    .mainSuccessAndReviewWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1200px;
      width: 100%;
      @media (max-width: 1200px) {
        justify-content: center;
      }
    }

    .successAndTextWrap {
      width: 33.33%;
      width: calc(100% / 3);

      margin: 10px 0px;
      @media (max-width: 1200px) {
        margin: 10px;
      }
      @media (max-width: 380px) {
        width: auto;
      }

      .referenceSuccessBox {
        width: 33.33%;
        width: calc(100% / 3);

        height: 200px;
        cursor: pointer;
        position: relative;
        .refSuccessImg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        @media (max-width: 380px) {
          width: auto;
        }
        .playButton {
          position: absolute;
          width: 70px;
          height: 40px;
          background: #1a90ff;
          top: 85px;
          left: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.7;
          transition: 0.2s ease;
        }

        &:hover {
          .successOpacityCover {
            opacity: 0.2;
          }
          .playButton {
            transform: scale(1.2);
            opacity: 0.9;
          }
        }
      }

      // .refVidOne {
      //   background: url(../../images/bikestore-success-bg.jpg);
      //   background-size: cover;
      // }

      // .refVidTwo {
      //   background: url(../../images/pharmacy-success-bg.jpg);
      //   background-size: cover;
      // }

      .successTextWrapper {
        width: 100%;
        @media (max-width: 380px) {
          padding: 4px;
        }
        .successTitle {
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 6px;
        }
        .successDescription {
          font-size: 14px;
          line-height: 1.4;
        }
      }
    }

    // iframe {
    //   width: 768px;
    //   height: 432px;
    //   @media (max-width: 800px) {
    //     height: 100%;
    //     width: 100%;
    //     left: 0;
    //     position: absolute;
    //     top: 0;
    //   }
    // }
  }

  .reviewTextWrap {
    width: 33.33%;
    width: calc(100% / 3);
    padding: 20px;
    @media (max-width: 1200px) {
      margin: 10px;
      width: 45%;
    }
    @media (max-width: 660px) {
      width: 100%;
    }
    .iconStarWrapperSectionBright {
      color: #ff711b;
      display: flex;
      margin-bottom: 16px;
    }

    .iconStarWrapperSectionDark {
      // color: #ff711b;
      color: white;
      // color: white;
      display: flex;
      margin-bottom: 16px;
    }

    .quoteWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
    }
    .customerReviewText {
      padding-bottom: 20px;
      font-size: 17px;
      text-align: left;
    }
    .customerReviewName {
      color: #1a90ff;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
    }

    .platformLogoWrapper {
      // font-weight: bold;
      color: #1a90ff;
      // color: rgb(79, 79, 79);
      padding-top: 2px;
      text-align: left;
    }
  }
}
