.industrySectionMain2 {
  padding-top: 60px;
  padding-bottom: 60px;
  .industrySection2Wrapper {
    display: flex;
    justify-content: space-between;
    margin: auto;
    .autoWidthBox {
      display: flex;
      padding: 0;
      flex-grow: 1;

      @media (max-width: 991px) {
      }
    }
  }
}

.industrySectionImage2 {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: flex-start;
  justify-content: center;
  .iSection2Img {
    max-width: 560px;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

.industrySectionMain2 {
  .industrySection2TextWrapper {
    @media (max-width: 991px) {
    }
    @media (max-width: 450px) {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    .industrySection2DiscriptionWrapper {
      width: 100%;
      text-align: left;
      @media (max-width: 1200px) {
        text-align: center;
      }
    }

    .industrySection2HeadWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 20px;
      @media (max-width: 1200px) {
        justify-content: center;
      }

      .headWrapper {
        // margin-bottom: 0px !important;
        font-size: 30px;
        color: #5a636c;
        font-family: "Poppins";
        @media (max-width: 450px) {
          font-size: 26px;
        }
      }

      .headerIcon {
        width: 34px;
        margin-right: 20px;
      }
    }
  }

  .industrySection2TextWrapper {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    width: 100%;
    @media (max-width: 1300px) {
      // margin-left: 30px !important;
    }

    @media (max-width: 991px) {
      text-align: center;
      align-items: center;
      padding-left: 0px;
      padding-top: 60px;
    }
  }
}
