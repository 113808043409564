@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";

.referencesContainer {
  display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  margin-bottom: 20px;
  // padding: 0px 0px 40px 0;
  margin-left: 22px;

  @media (max-width: 500px) {
    display: none;
  }

  @media (max-width: 991px) {
    margin-left: 0px;
  }
  .referencesWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // width: 90%;

    .referencesTop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .secondRefTop {
        padding-left: 50px;
        @media (max-width: 495px) {
          padding-left: 0px;
        }
      }
      .refTextTop {
        font-size: 30px;
        padding-bottom: 60px;
        font-weight: 500;
        color: #909090;
        @media (max-width: 420px) {
          padding-bottom: 0px;
        }
      }
      .refTopLeft {
        display: flex;
        padding-top: 20px;
        @media (max-width: 420px) {
          display: none;
        }
        .experience-box {
          font-size: 40px;
          font-weight: 700;

          .first-label {
            justify-content: center;
            display: flex;
            line-height: 40px;
            span {
              text-align: center;
            }
            .suffixBox {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: -4px;
            }
          }

          .second-label {
            font-size: 24px;
            line-height: 34px;
            padding-top: 14px;
            font-weight: 500;
            text-align: center;
          }
        }
        .experienceCounter {
          width: 200px;
        }
      }
      .refTopRight {
        padding-top: 20px;
        padding-left: 28px;
        @media (max-width: 950px) {
          width: 100%;
          padding-top: 60px;
        }
        .textTop {
          font-size: 40px;
          font-weight: 500;
          line-height: 40px;
        }
        .textBot {
          font-size: 26px;
          padding-top: 14px;
        }
      }
    }
    .referencesBot {
      width: 100%;
      display: flex;
      // margin-top: 80px;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      position: relative;
      float: left;

      @media (max-width: 490px) {
        margin-top: 30px;
        // flex-wrap: nowrap;
      }

      .refLogoGatsbyImage {
        margin-bottom: 30px;
        width: 120px;
        max-width: 130px;
        bottom: 15%;
      }
    }
  }
}

.slick-track {
  transition: transform 500ms linear 0px;
}
