.underlay {
  z-index: 5;
  background: rgba(0, 0, 0, 0.187);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mobileTopbar {
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  top: 0;
  height: 64px;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  transition: 0.3s ease;
}

.menuButton {
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  z-index: 990;
  width: 40px;
  height: 40px;
  margin-right: -4px;
  border-radius: 16%;
}

.hideMobileHeader {
  // transform: translate3d(0, -90px, 0);
  box-shadow: 0 8px 11px 0 transparent;
}

.showMobileHeader {
  // transform: translate3d(0, 0, 0);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  background: white !important;
}
