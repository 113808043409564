.mobileFunctionsBlockMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;

  @media (max-width: 570px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobileFunctionBlockItems {
    display: flex;
    width: 68%;
    flex-wrap: wrap;
    justify-content: space-between;
    // align-items: center;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 1200px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 570px) {
      width: 100%;
      margin-bottom: 20px;
      align-items: center;
    }

    @media (max-width: 410px) {
      width: 100%;
    }

    .mobileFunctionsHeader {
      width: 100%;
      margin: 8px;
      padding-bottom: 20px;
      font-size: 30px;
      font-weight: 600;
      display: flex;
      align-items: center;
      line-height: 30px;
      @media (max-width: 570px) {
        // justify-content: center;
        width: 88%;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }

    .mobileFunctionItem {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 30%;
      padding: 24px;
      margin: 8px;
      border: 1px solid #dedede;
      opacity: 0.8;
      border-radius: 5px;
      transition: 0.2s ease;

      // align-items: stretch;
      // height: auto;
      @media (max-width: 1200px) {
        width: 88%;
        padding: 14px;
      }

      .mobileFunctionItemHeadWrapper {
        display: flex;
        .mobileFunctionsItemHeaderIcon {
          margin-right: 10px;
          @media (max-width: 1200px) {
            margin-right: 12px;
          }
        }
        .mobileFunctionsItemHeader {
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          @media (max-width: 1200px) {
            font-size: 18px;
            font-weight: 400;
          }
        }
      }
      .mobileFunctionItemDescription {
        padding-top: 6px;
        font-size: 14px;
        text-align: left;
        @media (max-width: 1200px) {
          display: none;
        }
      }
      &:hover {
        background-color: #ecf8ff;
        border-color: #2299e3;
        box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.1);
        color: #333c4e !important;
        opacity: 0.9;
      }
    }
    .activeItem {
      background-color: #ecf8ff;
      border-color: #2299e3;
      box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.1);
      color: #333c4e !important;
      opacity: 1;
    }
  }

  .show {
    animation: fadeIn 500ms ease;
  }
  .unShow {
    animation: fadeOut 500ms ease;
  }
  @keyframes fadeOut {
    0% {
      transform: translate3d(0, 0px, 0);
      visibility: visible;
      opacity: 1;
    }
    50% {
      transform: translate3d(0, 0px, 0);
      opacity: 0.5;
      visibility: visible;
    }
    100% {
      transform: translate3d(0, -3px, 0);
      visibility: hidden;
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      transform: translate3d(0, -3px, 0);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      transform: translate3d(0, 0px, 0);
      opacity: 1;
      visibility: visible;
    }
  }
  .mobileShowcaseWrapper {
    width: 30%;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    display: flex;
    align-items: flex-end;

    @media (max-width: 1200px) {
      width: 100%;
      max-width: 300px;
      display: flex;
      justify-content: center;
    }

    .mobileShowcaseImage {
      width: 100%;
      max-width: 320px;
    }
  }
}
