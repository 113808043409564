.appStoreLinksMain {
  // background: url(../../../images/last-wave-bg.png) no-repeat bottom center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: bottom;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  display: flex;
  justify-content: center;
  background-size: 100%;
  background-color: #1a90ff;

  .appstoreText {
    width: 80%;
  }

  .buttonsRow {
    display: flex;
    @media (max-width: 400px) {
      flex-direction: column;
    }
    .buttonLeftLink {
      margin-right: 20px;
      @media (max-width: 400px) {
        margin-right: 0;
      }
    }
  }
}
