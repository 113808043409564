$base_font_size: 16px;
$base_line_height: 26px;
$font_heading: "Poppins";
// $font_heading: DM Sans;
$font_main: "Poppins";
$font_heading_weight: 500;
$font_main_weight: 400;
$retina: "(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)";

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 900;

$action_1: #1a90ff;
$action_2: #1a90ff;
$action_3: #e93a7d;
$color_main: #283845;
$color_heading: #5b636c;
$color_white: #ffffff;
$color_gray: #ebeaed;
$bg_dark: #283845;
$bg_light: #ffffff;

$color_red: #ea3223;
$color_red_hover: #e10000;
$color_green: #18c967;
$color_green_hover: #0cb658;
$color_facebook: #2e4da7;
$color_twitter: #0aa0f5;
$color_dribbble: #e93a7d;
