.customHeaderMain {
  justify-content: center;
  @media (min-width: 992px) {
    .onlySmallScreen {
      display: none;
    }
  }

  .titleWrapper {
    display: flex;
    flex-direction: column;

    .freeSubHeader {
      font-weight: 600;
      color: #19a0ff;
      font-size: 47px;
      text-align: left;
      @media (max-width: 991px) {
        font-size: 44px;
        padding-top: 0px;
        padding-bottom: 30px;
        text-align: center;
      }
      @media (max-width: 400px) {
        font-size: 34px;
      }
    }
  }

  display: flex;
  @media (max-width: 490px) {
    background: #19a0ff;
  }

  .landingBackgroundImageWrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    .tabletMobileBackground {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 75% 20%;
      object-position: 75% 20%;
      width: 100%;
      display: none;
      @media (max-width: 490px) {
        display: flex;
      }
    }
  }

  .headerMainContainer {
    min-height: 80vh;
    background: white;
    overflow: visible;
    padding-top: 80px;
    display: flex;
    @media (max-width: 991px) {
      justify-content: center;
    }
    @media (max-width: 576px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: 490px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    .customFormAndImgWrapper {
      padding: 0;
      padding-top: 60px;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        max-width: 70%;
        justify-content: center;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }

      .headerFormWrapper {
        max-width: 40%;
        min-width: 40%;
        @media (max-width: 991px) {
          text-align: center;
          max-width: 100%;
          min-width: 100%;
        }
        @media (max-width: 767px) {
          text-align: center;

          min-width: 80%;
        }
        .titleWrapper {
          @media (max-width: 390px) {
            padding-bottom: 30px;
          }
          @media (max-width: 991px) {
            padding-bottom: 18px;
          }

          .customHeaderMainTitle {
            font-weight: 600;
            color: #283845;
            text-align: left;
            padding-bottom: 10px;
            @media (max-width: 991px) {
              text-align: center;
              padding-bottom: 16px;
              margin-top: 34px;
            }
          }

          .customHeaderTitleFontSize {
            font-size: 50px;
            @media (max-width: 991px) {
              font-size: 44px !important;
            }
            @media (max-width: 390px) {
              font-size: 34px !important;
            }
          }

          .customHeaderTitleFontSizeSmall {
            font-size: 46px;
            @media (max-width: 991px) {
              font-size: 44px;
            }
          }

          .customHeaderTitleFontSizeExtraSmall {
            font-size: 48px;
            @media (max-width: 991px) {
              font-size: 44px;
            }
            @media (max-width: 390px) {
              font-size: 34px !important;
            }
          }

          .customSubHeaderName {
            font-weight: 300;
            color: #283845;
            line-height: 48px;
            flex: 0 0 auto;
            padding-top: 10px;
            align-self: flex-start;

            @media (max-width: 991px) {
              text-align: center;
              align-self: center;

              font-weight: 300;
              margin-top: 0;
              margin-bottom: 11px;
              color: #283845;
              background: none;
              padding-top: 0px;
            }
            @media (max-width: 767px) {
              font-size: 36px !important;
            }
            @media (max-width: 390px) {
              margin-top: -6px;
            }
          }

          .subHeaderFontSize {
            font-size: 50px;
            @media (max-width: 991px) {
              font-size: 42px;
            }
            @media (max-width: 390px) {
              font-size: 36px !important;
            }
          }

          .subHeaderFontSizeSmall {
            font-size: 44px;
            @media (max-width: 991px) {
              font-size: 42px;
            }
          }

          .subHeaderFontSizeExtraSmall {
            font-size: 48px;
            @media (max-width: 991px) {
              font-size: 40px;
            }
            @media (max-width: 390px) {
              font-size: 34px !important;
            }
          }
        }

        .desktopSubHeaderText {
          font-size: 18px;
          margin-bottom: 0;
          font-weight: 500;
          padding-top: 10px;
          padding-bottom: 16px;
          line-height: 1.6;
          color: #34383c;
          text-align: left;
          margin-top: 0;
          @media (max-width: 991px) {
            text-align: center;
            padding-top: 12px;
          }
          @media (max-width: 500px) {
            font-size: 16px;
            line-height: normal;
            padding-top: 0px;
          }
        }

        .customHeaderInputField {
          width: 80%;
          margin-bottom: 10px;
          @media (max-width: 1200px) {
            width: 100%;
          }
          .inputFieldLanding {
            background-color: #f2f2f2;
            color: #7c8c95;
            text-align: center;
            width: 100%;
            font-size: 18px;
          }
        }
        .customHeaderButtonWrapper {
          display: flex;
          justify-content: flex-start;
          @media (max-width: 991px) {
            justify-content: center;
          }

          // flex-flow: row wrap;
          width: 80%;
          align-items: center;
          @media (max-width: 1200px) {
            width: 100%;
          }

          .customHeaderButton {
            max-width: 250px;
            width: 100%;
            vertical-align: top;
            height: 50px;
            line-height: 50px;
            padding: 0px 24px;
            border-radius: 5px;
            color: white;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            transition: all 0.25s;
            cursor: pointer;
            font-weight: 500;
            background: #1a90ff;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        .customHeaderLiveDemoBlock {
          padding-top: 20px;
          font-size: 20px;
          color: #283845;
          display: flex;
          @media (max-width: 991px) {
            text-align: center;
            align-items: center;
            justify-content: center;
          }
          .liveDemoClickable {
            color: #19a0ff;
            cursor: pointer;
            padding-right: 6px;
            padding-left: 6px;
            &:hover {
              opacity: 0.7;
            }
          }
        }

        .freeForUpTo {
          text-align: left;
          @media (max-width: 991px) {
            text-align: center;
          }
        }

        .smallScreenImageWrapper {
          margin-bottom: 40px;
        }

        .customHeaderImageSmall {
          width: 90%;
        }
      }

      .customImageWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        @media (max-width: 991px) {
          display: none;
        }
        .customHeaderImage {
          position: absolute;
          top: 40px;
          left: 40px;
          z-index: 10;
          max-width: 650px;
          border-radius: 6px;
          margin-left: 60px;
          box-shadow: 0px 5px 40px 0px #00000040;
          animation: 0.6s ease-out slideInFromTop;
          @media (min-width: 2000px) {
            width: 720px;
          }
          @media (max-width: 991px) {
            display: none;
          }
        }

        @keyframes slideInFromTop {
          0% {
            transform: translateY(-6%);
            opacity: 0.8;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .customHeaderImageBackground {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 6;
          max-width: 750px;
          margin-left: 60px;
          animation: 0.6s ease-out slideInFromBottom;
          @media (min-width: 2000px) {
            width: 720px;
          }
          @media (max-width: 991px) {
            display: none;
          }
        }

        @keyframes slideInFromBottom {
          0% {
            transform: translateY(16%);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }
}
