@import "../Layout/flexbox.scss";
@import "../Layout/template.scss";
@import "../Layout/_templateVariables.scss";
@import "../Layout/_templateFramework.scss";
@import "../Layout/_templateLayout.scss";

.headerMainWrapper {
  transition: 0.3s ease;
  width: 100%;
  height: 106px;
  z-index: 1000;
  position: fixed;

  .reviewNavWrap {
    display: flex;
    .iconStarNavWrapper {
      color: orange;
      font-size: 14px;
      padding-right: 20px;
    }
    .reviewNavQuote {
      font-size: 14px;
      color: grey;
    }
  }
  .loginButtonNavTop {
    border-radius: 3px;
    color: #7f7f7f;
    font-weight: 500;
    font-size: 15px;
    padding: 0px 16px;
    letter-spacing: 2px;
    border: 1px solid #a2a2a2;
    &:hover {
      color: white;
      background: #19a0ff;
      border-color: #19a0ff;
    }
  }
}

.hideHeader {
  // transform: translate3d(0, -90px, 0);
  box-shadow: 0 8px 11px 0 transparent;
}

.showHeader {
  // transform: translate3d(0, 0, 0);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  background: white !important;
}

.dropdownList {
  display: block !important;
  z-index: 1030;
  position: absolute;
  left: 50%;
  min-width: 300px;
  padding: 20px 20px 10px 20px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
  visibility: hidden;
  opacity: 0;
  transform: translate3d(-50%, 10px, 0);
  // transition: all 0.2s ease-in-out;
  transition-property: opacity, visibility, transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  transform-origin: top center;
  font-size: 1rem;
  color: #161c2d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(22, 28, 45, 0.15);
  border-radius: 0.375rem;

  .dropdownListHolder {
    display: flex;
    flex-direction: column;
    position: relative;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // visibility: hidden;
    // opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;

    .listItemLink {
      text-align: center;
      color: #161c2d;
      text-decoration: none;
      font-weight: bold;
      color: rgb(70, 70, 70);
    }

    .dropdownItemWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 10px 12px 10px;
      align-items: center;
      cursor: pointer;
      .dropdownImgWrapper {
        width: 20%;
        margin-right: 10px;
        .dropdownIcon {
          width: 36px;
          opacity: 0.8;
          height: 36px;
        }
      }
      .dropdownItemText {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 4px;
        .dropdownIconDescription {
          line-height: 18px;
          color: grey;
        }
      }
      &:hover {
        .listItemLink {
          color: #1990ff;
        }
      }
    }

    .seperationLine {
      width: 90%;
      margin: auto;
      height: 1px;
      background: rgb(230, 230, 230);
      margin-bottom: 10px;
    }
  }
}

.dropdownList .listItemLink:hover {
  color: #1990ff;
}

.functionNavLink {
  display: flex;
}

.whiteLink {
  color: white;
}

.dropdownList:hover {
  visibility: visible;
  opacity: 1;
  transform: translate3d(-50%, 0px, 0);
}

.functionNavLink:hover + .dropdownList {
  visibility: visible;
  opacity: 1;
  transform: translate3d(-50%, 0px, 0);
}

.showDropDown {
  visibility: visible;
  opacity: 1;
}

.hideDropDown {
  visibility: hidden;
  opacity: 0;
}

a.navMainLink {
  padding: 6px 0;
  color: #283845;
  &:hover {
    color: #19a0ff !important;
  }
}

.navLoginButton {
  padding: 4px 20px;
  border: 1px solid #19a0ff;
  border-radius: 4px;
  background: white;
  color: #19a0ff;
  transition: 0.2s ease;
  &:hover {
    background: #117fce;
    border-color: #117fce;
    color: white;
  }
}

.registerFreeButton {
  padding: 2px 16px;
  border-radius: 4px;
  cursor: pointer;
  background: #19a0ff;
  color: white;
  transition: 0.2s ease;
  &:hover {
    background: #117fce;
    color: white;
  }
}
